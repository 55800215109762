
import { defineComponent, PropType } from 'vue';
import { numberfuns } from 'cx-utils';
import { IPostDetailItem } from '../../type.d';

export default defineComponent({
  emits: ['select-img', 'image'],
  props: {
    data: {
      type: Object as PropType<IPostDetailItem>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    /* eslint-disable no-restricted-globals */
    const intValidator = (num: string | number) => {
      const Nnum = Number(num);
      if (isNaN(Nnum) || Nnum < 0) return 0;
      if (Nnum < 10000) return Nnum;
      const Fnum = +numberfuns.formatFloat(Nnum / 10000, { pos: 1, round: true });
      return `${Fnum} w`;
    };

    const handleSelectImgItem = (row: IPostDetailItem, type: 'quick'|'normal' = 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      emit(
        'select-img',
        {
          id,
          key: row.postId,
          // categoryClass: row?.secondCname || row?.firstCname,
          channelId: row.channelId,
        },
        type,
      );
    };

    const handleImageItem = (row: IPostDetailItem) => {
      emit('image', row);
    };

    return {
      handleImageItem,
      handleSelectImgItem,
      intValidator,
    };
  },
});
