import { ref, computed, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { getPostDetailList, getBloggerDetail } from '@/modules/miaokuan-manage/blogger/api';
import { ICtInsFindByPoIdRes } from '@/modules/miaokuan-manage/blogger/api/type.d';
import { cloneDeep } from 'lodash-es';
import {
  IPostDetailItem,
  IPostDetailParams,
  // IParams,
  ISortParams,
  ISearchKeys,
} from '../type.d';
import {
  SORT_WORD_LIST,
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
  // IS_MY_INS_ENUMS,
} from '@/modules/miaokuan-manage/common/constant';
// import { useDictionaryStore } from '@/store/dictionary';
// import { CX_CUSTOM_DICTIONARY_KEY } from '@/constant/dictionary';
import { usePagination } from './use-detail-pagination';
import { trackingExposure } from '@/utils/tracking';
/* 支持 列表查询 */

export default () => {
  const router = useRoute();
  const poId = ref<string>(router.query.poId as string);
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  // const dictionaryStore = useDictionaryStore();
  const defaultSearchKeys = ref<Array<ISearchKeys>>([]);
  const sortConfig = ref<ISortParams>({
    prop: SORT_WORD_ENUM.LATELY,
    order: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const baseParams: IPostDetailParams = {
    poId: poId.value,
  };
  const params = ref<IPostDetailParams>(cloneDeep(baseParams));
  const searchType = ref<'default'|'advanced'>('default');
  const finish = ref(false);
  const goodsList = ref<IPostDetailItem[]>([]);
  const getPoDetail = async ():Promise<ICtInsFindByPoIdRes> => {
    try {
      const { data } = await getBloggerDetail({
        poId: router.query.poId,
      });
      return data as unknown as ICtInsFindByPoIdRes;
    } catch (e) {
      console.log('获取博主详情 error', e);
      return Promise.reject(e);
    }
  };
  const getList = async (current: number) => {
    try {
      const res = await getPostDetailList({
        poId: poId.value,
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
      });

      const newList = res.data?.list || [];
      goodsList.value.push(...newList);
      trackingExposure(newList, 'postId', {
        eventName: '图片曝光量',
      });
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= reqPageIndex.value * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    goodsList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    sortConfig.value.prop = val;
    sortConfig.value.order = sortConfig.value.order === SORT_TYPE_ENUM.DOWN
      ? SORT_TYPE_ENUM.TOP : SORT_TYPE_ENUM.DOWN;
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getPostDetailList({
            poId: poId.value,
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IPostDetailItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        goodsList.value.push(...list);
        trackingExposure(list, 'postId', {
          eventName: '图片曝光量',
        });
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });
  return {
    getPoDetail,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    defaultSearchKeys,
    searchType,
    baseParams,
    handleChangeSort,
    sortConfig,
    sortList: SORT_WORD_LIST,
    params,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
