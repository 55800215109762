
import { defineComponent, ref, nextTick, onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { processRouterQueryParams } from '@/utils/';
import useBlogDetail from '../../composables/use-detail-list';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import { cloneDeep } from 'lodash-es';
import type {
  ISearchSettingConfig,
  IPostAdvancedSearchConfig,
  IPostDefaultSearchConfig,
  ISearchKeys,
  IPostDetailItem,
  IPostDetailParams,
} from '../../type.d';
import { ICtInsFindByPoIdRes } from '@/modules/miaokuan-manage/blogger/api/type.d';
import type { IDesignTaskSelectProductPictureReq } from '@/modules/miaokuan-manage/common/api/type.d';
import type { IMkSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import type { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { SORT_TYPE_ENUM } from '@/modules/doupinku-manage/constant';
import { postInsSubscribe } from '@/modules/miaokuan-manage/sub/api';
import { confirmImage } from '@/modules/miaokuan-manage/common/api';
import { ElMessage, ElForm } from 'element-plus';
import NumberRangeInput from '@/modules/miaokuan-manage/common/components/number-range-input';
import SelectImgDialog from '@/modules/miaokuan-manage/common/components/select-img-dialog/index.vue';
import GoodItem from '../../components/good-item/index.vue';
// import Pagination from '@/modules/miaokuan-manage/components/pagination/index.vue';
import IconArrowDefault from '@/assets/images/resource/i_arrow_default.svg';
import IconArrowDown from '@/assets/images/resource/i_arrow_down.svg';
import IconArrowTop from '@/assets/images/resource/i_arrow_top.svg';
import { SUB_STATUS_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    GoodItem,
    SelectImgDialog,
    NumberRangeInput,
    // Pagination,
    CancelSub,
  },
  setup() {
    const router = useRouter();
    const { intValidator } = useMathFormat();
    const goodsEl = ref<null|HTMLElement>(null);
    const defaultSearchEl = ref<InstanceType<typeof ElForm> | null>(null);
    const detailData = reactive<{poDetail:ICtInsFindByPoIdRes;}>({
      poDetail: {
        poId: '',
        poName: '',
        poFirstname: '',
        postCnt: '',
        fanCnt: '',
        followPoCnt: '',
        poLogoUrl: '',
        poDescription: '',
        poUrl: '',
        biographyLink: '',
        alreadySubscribe: true,
      },
    });

    // 列表查询
    const {
      getPoDetail,
      handleCurrentSizeChange,
      defaultSearchKeys,
      searchType,
      baseParams,
      params,
      goodsList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
    } = useBlogDetail();

    // 默认搜索条件
    const defaultSearchConfig = ref<IPostDefaultSearchConfig<IPostDetailParams>>({
      params: cloneDeep(baseParams),
    });

    // 缓存到本地的需展示搜索项key[]
    const localSaveSettingKeys = (keys: string[]) => {
      localStorage.setItem('style_search_keys', JSON.stringify(keys));
    };

    // 初始化页面需要展示的搜索项
    const initSettingConfig = () => {
      const keys: Array<ISearchKeys> = [
        // SEARCH_BASE_SETTING_ENUM.CATEGORY, // 品类
      ];
      defaultSearchKeys.value = keys;
      localSaveSettingKeys(keys);
    };
    initSettingConfig();

    // 搜索项配置
    const searchSettingConfig = ref<ISearchSettingConfig>({
      visible: false,
    });
    const handleChangeSettingConfigShow = (visible: boolean) => {
      searchSettingConfig.value.visible = visible;
    };
    const handleConfirmSearchSetting = (keys: Array<ISearchKeys>) => {
      defaultSearchKeys.value = [...keys];
      localSaveSettingKeys(keys);
    };

    // 高级搜索
    const advancedSearchConfig = ref<IPostAdvancedSearchConfig<IPostDetailParams>>({
      visible: false,
      params: cloneDeep(baseParams),
    });
    const handleAdvancedSearchModalShow = () => {
      advancedSearchConfig.value.visible = true;
    };

    const handleReset = () => {
      searchType.value = 'default';
      advancedSearchConfig.value.params = cloneDeep(baseParams);
      defaultSearchConfig.value.params = cloneDeep(baseParams);
      params.value = cloneDeep(baseParams);
      reload();
    };

    // 选图-设计任务
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    // 选图｜快速选图
    const selectImgConfig = ref<{data:IDesignTaskSelectProductPictureReq;visible:boolean;}>(
      {
        data: { id: '', channelId: '', categoryClass: '' },
        visible: false,
      },
    );
    const handleSelectImgItem = async (param:IDesignTaskSelectProductPictureReq, type: 'quick' | 'normal') => {
      const { id = '', key = '' } = param;
      let eventDetails: { [key: string]: string; } = {
        eventName: '选图',
        goodId: key || '',
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (type === 'quick' && id) {
        await confirmImage(param);
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgConfig.value = { data: param, visible: true };
    };

    // 点击图片跳转详情
    const handleImageItem = (row: IPostDetailItem) => {
      trackingClick({
        eventName: '查看图片详情',
        goodId: row.postId,
      });
      const newPage = router.resolve({
        name: 'MiaoPinDetail',
        query: {
          postId: row.postId,
        },
      });
      window.open(newPage.href);
    };

    const status = ref<SUB_STATUS_ENUMS>(SUB_STATUS_ENUMS.SUB);

    // 订阅
    const handleSub = async () => {
      trackingClick({
        eventName: '订阅',
        targetId: detailData.poDetail.poId,
      });
      const param:IMkSubscribeReq = {
        poId: detailData.poDetail.poId as string,
        isSubscribe: SUB_STATUS_ENUMS.SUB,
      };
      await postInsSubscribe(param);
      ElMessage.success('订阅成功');
      status.value = SUB_STATUS_ENUMS.SUB;
    };
    // 取消订阅
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: [] },
    );
    const handleSubCancel = async () => {
      trackingClick({
        eventName: '取消订阅弹窗-确定',
        targetId: detailData.poDetail.poId,
      });
      const param:IMkSubscribeReq = {
        poId: detailData.poDetail.poId as string,
        isSubscribe: SUB_STATUS_ENUMS.CANCEL,
      };
      await postInsSubscribe(param);
      ElMessage.success('取消订阅成功');
      cancelSubConfig.value.visible = false;
      status.value = SUB_STATUS_ENUMS.CANCEL;
    };
    const openCancelSub = () => {
      trackingClick({
        eventName: '取消订阅',
        targetId: detailData.poDetail.poId,
      });
      cancelSubConfig.value.visible = true;
    };
    const handleLinkIns = () => {
      trackingClick({
        eventName: '跳转博主ins主页',
        targetId: detailData.poDetail.poId,
      });
      window.open(detailData.poDetail.poUrl);
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: selectImgConfig.value.data.key,
        taskId: id,
      });
    };

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      urlParams.pageNum = 0;
      params.value = urlParams;
      detailData.poDetail = (await getPoDetail());
      status.value = SUB_STATUS_ENUMS[detailData.poDetail.alreadySubscribe ? 'SUB' : 'CANCEL'];
      initPagination(goodsEl.value!);
      reload();
    };

    onMounted(() => {
      init();
    });

    return {
      ...toRefs(detailData),
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      handleReset,
      defaultSearchEl,
      handleAdvancedSearchModalShow,
      defaultSearchKeys,
      defaultSearchConfig,
      advancedSearchConfig,
      handleChangeSort,
      sortList,
      sortConfig,
      handleChangeSettingConfigShow,
      handleConfirmSearchSetting,
      searchSettingConfig,
      designTaskOptions,
      handleSelectImgItem,
      selectImgConfig,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      handleImageItem,
      status,
      handleSub,
      handleLinkIns,
      handleSubCancel,
      cancelSubConfig,
      openCancelSub,
      intValidator,
      handleSelectSuccess,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <div class="conditions-container">
            <section class="header-info flex flex-justify-between">
              <div class='left'>
                <div class="left-img">
                  <custom-image
                    style="width: 100px; height: 100px;border-radius: 50px"
                    src={this.poDetail.poLogoUrl}
                    fit="cover"
                  ></custom-image>
                </div>
                <div class="left-basic">
                  <strong>{this.poDetail.poName}</strong>
                  <div class='left-ins'>
                    <div class='left-ins__infoItem'>
                      <span class='margin-right-5'>{this.intValidator(this.poDetail.postCnt)}</span>
                      <span>帖子</span>
                    </div>
                    <div class='left-ins__infoItem'>
                      <span class='margin-right-5'>{this.intValidator(this.poDetail.fanCnt)}</span>
                      <span>粉丝</span>
                    </div>
                    <div class='left-ins__infoItem'>
                      <span class='margin-right-5'>{this.poDetail.followPoCnt}</span>
                      <span>关注</span>
                    </div>
                  </div>
                  <div class='left-description'>
                    <p v-html={this.poDetail.poFirstname}></p>
                    <p v-html={this.poDetail.poDescription} title={this.poDetail.poDescription}></p>
                    <p v-html={this.poDetail.biographyLink}></p>
                  </div>
              </div>
              </div>
              <div class='right'>
                <div>
                  <el-button
                    onClick={this.handleLinkIns}
                  >INS主页</el-button>
                  <el-button
                    type={this.status === SUB_STATUS_ENUMS.CANCEL ? 'primary' : 'default'}
                    icon={this.status === SUB_STATUS_ENUMS.CANCEL ? 'el-icon-plus' : ''}
                    onClick={this.status === SUB_STATUS_ENUMS.CANCEL ? this.handleSub : this.openCancelSub}
                  >{this.status === SUB_STATUS_ENUMS.CANCEL ? '订阅' : '取消订阅'} </el-button>
                </div>
              </div>
            </section>
          </div>
          <div class="sorting-content">
            {this.sortList.map(item => (
              <div
                key={item.value}
                class="item"
                onClick={() => this.handleChangeSort(item.value)}
              >
                <div>{item.label}</div>
                <div class="arrow">
                  <img src={IconArrowDefault} class="arrow_icon" />
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.TOP
                  && <img src={IconArrowTop} class="arrow_icon" />
                  }
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.DOWN
                  && <img src={IconArrowDown} class="arrow_icon" />
                  }
                </div>
              </div>
            ))}
          </div>
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <good-item
                  key={i}
                  data={v}
                  onSelectImg={this.handleSelectImgItem}
                  onImage={this.handleImageItem}
                />
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgConfig.visible, 'visible']}
          data={this.selectImgConfig.data}
          options={this.designTaskOptions}
          onSuccess={this.handleSelectSuccess}
        />
        <cancel-sub v-model={[this.cancelSubConfig.visible, 'visible']} onSubCancel={this.handleSubCancel}/>
      </div>
    );
  },
});
